<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <!-- <div class="card-body"> -->
        <div class="row card-body form-group">
          <div class="form-group col-md-3">
            <label class="col-md-12">{{ $t("DATAINICIO.NOME") }}</label>
            <input
              required
              type="date"
              class="form-control"
              v-model="filtro.data_inicio"
            />
          </div>
          <div class="form-group col-md-3">
            <label class="col-md-12">{{ $t("DATAFINAL.NOME") }}</label>
            <input
              required
              type="date"
              class="form-control"
              v-model="filtro.data_fim"
            />
          </div>
          <div class="form-group col-md-3">
            <label class="col-md-12">Tipo de atendimento: </label>
            <select v-model="filtro.tipo_atendimento" class="form-control">
              <option value="1">Consulta</option>
              <option value="2">Retorno</option>
            </select>
          </div>

          <!-- <div class="form-group col-md-4">
            <label class="col-md-12">Medico :</label>

            <b-input-group class="mb-2 mr-sm-2 mb-sm-0 bg-secondary">
              <input
                required
                type="text"
                class="form-control"
                v-model="nome_pesquisa"
                @click="pesquisar()"
              />
              <b-button v-b-modal.SelecionarLote class="btn btn-indo">
                <i class="fa fa-search search-icon m-0 p-0"></i>
              </b-button>
            </b-input-group>

            <div>
              <b-modal
                id="SelecionarLote"
                hide-footer
                hide-header
                title="SelecionarLote"
                ref="modal_lote"
              >
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="nome_pesquisa"
                />
                <b-table
                  :fields="['Documento', 'Nome', 'acoes']"
                  :items="lista_funcionarios"
                  :per-page="perPage"
                  :current-page="currentPage"
                  id="atendimento-table"
                  class="table table-hover"
                  show-empty
                  empty-text="Nenhum registro encontrado!"
                >
                  <template #cell(acoes)="{ item }">
                    <button
                      @click="selecionarEstoque(item)"
                      class="btn btn-light mx-1"
                      v-b-tooltip.hover
                      title="Clique para selecionar paciente"
                    >
                      Selecionar
                    </button>
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="lista_funcionarios.length"
                  :per-page="perPage"
                  aria-controls="atendimento-table"
                >
                </b-pagination>
              </b-modal>
            </div>
          </div> -->
          <div class="col-md-3 justify-content-end row mt-8">
            <div class="ml-1">
              <button class="btn btn-success" @click="pesquisar()">
                <i class="fa fa-search search-icon m-0 p-0"></i>
              </button>
            </div>
            <div class="ml-1">
              <button class="btn btn-info" @click="gerarExcel()">
                <i class="fa fa-print m-0 p-0"></i>
              </button>
            </div>
            <!-- <div class="ml-1">
              <button class="btn btn-primary" @click="media()">
                <i class="fa fa-print m-0 p-0"></i>
              </button>
            </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-11 pt-3">
      <div class="card full-height bg-ligth">
        <!-- <div class="card-header">
          <h3 class="mb-0">Salidas de Productos General</h3>
        </div> -->
        <div class="card-body">
          <b-table
            :fields="[
              'data',
              'paciente',
              'medico',
              'motivo_consulta',
              'diagnostico',
              'tipo',
              'acoes',
            ]"
            :items="atendimentos"
            :per-page="perPage"
            :current-page="currentPage"
            id="saida_produto"
            class="table table-responsive table-head-custom table-vertical-center table-head-bg table-borderless"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_Sala"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  disabled
                  v-b-tooltip.hover
                  title="Imprimir (no disponible)"
                >
                <i class="flaticon2-print m-0 p-0"></i>
                </button>
              </div>
            </template>
            <template #cell(tipo)="{ item }"
              ><span></span>
              <span
                style="font-size: 12px"
                class="label label-lg label-inline"
                :class="{
                  'label-light-success': item.tipo === 1,
                  'label-light-info': item.tipo === 2,
                }"
              >
                {{
                  item.tipo === 1
                    ? "Consulta"
                    : item.tipo === 2
                    ? "Retorno"
                    : "no declarado"
                }}
              </span>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="atendimentos.length"
            :per-page="perPage"
            aria-controls="saida_produto"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins: { fireAlert },
  data() {
    return {
      currentPage: 2,
      perPage: 10,
      filtro: {
        data_inicio: null,
        data_fim: null,
        tipo_relatorio: "lista",
        filial_id: null,
        tipo_atendimento: null,
      },
      nome_pesquisa: null,
      atendimentos: [],
    };
  },
  watch: {
    nome_pesquisa() {
      if (this.nome_pesquisa.length >= 3) {
        var p = {
          texto: this.nome_pesquisa,
          tipo: "geral",
        };
        this.$store.dispatch("produto/pesquisar_estoque_por_lotes", p);
      }
      if (this.nome_pesquisa.length == 0) this.filtro.estoque_produto_id = null;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Relatorio de Atendimentos" },
    ]);
  },
  created() {
    // this.listar_saida_produtos();
    this.listar_requerimentos();
  },
  computed: {
    lista_saida_produtos() {
      return this.$store.state.produto.lista_saida_produtos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },
    lista_atendimentos() {
      return this.$store.state.atendimento.lista_atendimentos;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
  },
  methods: {
    async pesquisar() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("atendimento/relatorio_atendimentos", this.filtro)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
        });
      this.atendimentos = this.lista_atendimentos.atendimentos;
    },
    async listar_requerimentos() {
      await this.$store.dispatch("configEmpresa/listar_filial");
      await this.$store.dispatch("produto/listar_produtos");
    },
    pesquisar_funcionario() {
      this.$store.dispatch(
        "produto/pesquisar_estoque_por_lotes",
        this.nome_pesquisa
      );
    },
    async gerarExcel() {
      this.filtro.tipo_relatorio = "excel";
      await this.$store.dispatch(
        "atendimento/relatorio_atendimentos_excel",
        this.filtro
      );
    },
    async media(){
      this.filtro.tipo_relatorio = "excel";
      await this.$store.dispatch(
        "atendimento/media_atendimentos_filial",
        this.filtro
      );
    },
    selecionarEstoque(item) {
      this.filtro.estoque_produto_id = item.id;
      this.filtro.produto_id = item.produto_id;
      this.$refs["modal_lote"].hide();
    },
    atualizar(value) {
      this.$router.push({
        name: "createSaidaProduto",
        params: { saida_produto_id: value.id },
      });
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("produto/listar_produto", value.id);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + value,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (value == "desfazer a saída") this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      // await this.$store.dispatch("produto/bloquear_produto", value);
      // this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>